import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styles from './index.module.css';
import { MapPin, Mail, Phone } from 'react-feather';

const IndexPage = () => (
  <Layout>
    <SEO title="" />
    <div className={styles.container}>
      <div className={styles.hero}>
        <div className={styles.heroBg}></div>
        <div className={styles.heroContent}>
          <div className={styles.heroText}>
            <h3 className={styles.subtitle}>Voor al uw Landbouw & Grondwerken.</h3>
              <ul className={styles.info}>
                <li><Phone /><a href="tel:+32 3 313 74 36">+32 3 313 74 36</a></li>
                <li><Mail /><a href="mailto:van.dijck.jos.bvba@skynet.be">van.dijck.jos.bvba@skynet.be</a></li>
                <li><MapPin /><a href="https://www.google.com/maps/place/Landbouw-+En+Grondwerken+Van+Dijck+Jos/@51.3774179,4.6772161,15z/data=!4m2!3m1!1s0x0:0xfe4e567224c0fd77?sa=X&ved=2ahUKEwjS8MXy0sjpAhWVr3EKHaSFC7kQ_BIwE3oECA8QCg">Sint-lenaartseweg 211, 2990 Loenhout</a></li>
              </ul>
              <a href="mailto:van.dijck.jos.bvba@skynet.be" className={styles.btn}>Contacteer ons</a>
          </div>
        </div>
      </div>

      <div className={styles.address}>
        <div className={styles.addressContent}>
          <h3>Hier kan u ons vinden</h3>
          <p>
            Sint-lenaartseweg 211,<br/>
            2990 Loenhout<br/>
            Belgie
          </p>
          <a className={styles.btn} href="https://www.google.com/maps/place/Landbouw-+En+Grondwerken+Van+Dijck+Jos/@51.3774179,4.6772161,15z/data=!4m2!3m1!1s0x0:0xfe4e567224c0fd77?sa=X&ved=2ahUKEwjS8MXy0sjpAhWVr3EKHaSFC7kQ_BIwE3oECA8QCg">Bekijk in Google Maps</a>
        </div>
        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7394.085938261644!2d4.677271404614787!3d51.37750765308927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c4028a8f7a61c7%3A0xfe4e567224c0fd77!2sLandbouw-%20En%20Grondwerken%20Van%20Dijck%20Jos!5e0!3m2!1sen!2suk!4v1590229302415!5m2!1sen!2suk" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
      </div>

      <div className={styles.photos}>
        <div className={styles.photo}>
          <video src="/background.mp4" autoPlay muted playsInline loop></video>
        </div>
        <div className={styles.photo}>
          <img src="/photo-2.jpg" alt="Xerion & Hakselaar" />
        </div>
        <div className={styles.photo}>
          <img src="/photo-3.jpg" alt="Kranen" />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

// <div className={styles.content}>
//   {%}
//   <img className={styles.logo} src="/jvdlogo-color.png" alt="logo" />
//   <h1>Van Dijck Jos BVBA</h1>
//   <h2>LANDBOUW- & GRONDWERKEN</h2>
//   <p>Sint Lenaartseweg 211, 2990 Wuustwezel, Belgie (bekijk op <a href="https://www.google.com/maps/place/Landbouw-+En+Grondwerken+Van+Dijck+Jos/@51.3774179,4.6772161,15z/data=!4m2!3m1!1s0x0:0xfe4e567224c0fd77?sa=X&ved=2ahUKEwjS8MXy0sjpAhWVr3EKHaSFC7kQ_BIwE3oECA8QCg">google maps</a>)</p>
//   <p>Bel:<a href="tel:+32 3 313 74 36"> +32 3 313 74 36</a></p>
//   <p>Email:<a href="mailto:van.dijck.jos.bvba@skynet.be">van.dijck.jos.bvba@skynet.be</a></p>
//
// </div>
// <video className={styles.background} src="/background.mp4" autoPlay muted playsInline loop></video>
// </div>
